body{
  font-family:'Ubuntu', sans-serif,'Urbanist', sans-serif;


}
*{
  padding: 0;
margin: 0;
box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
